<template>
  <div id="comfire">
    <Else></Else>
     <div class="comfire-box">
        <div class="comfire-title">企业认证</div>
        <div class="comfire-middle">
            <div class="comfire-content">
                <div class="content1">滨江区企业</div>
                <div class="content2">仅限杭州高新区（滨江）的企业使用，您需提交相关信息材料，审核通过后，本系统将为您提供多个公司的商标管理</div>
                <div class="content3">
                    <div :class="change">
                        <div @click="changetype('apply')" class="applym">申请企业认证</div>
                        <div @click="changetype('test')" class="testm">已认证</div>
                    </div>
                </div>
                <div class="content4" v-show="change == 'apply'">
                     <div class="comfire-company">
                        <span>企业名称</span>
                        <input type="text" placeholder="请输入合法的企业名称" v-model="companyName" />
                        <div class="comfire-msg">{{companyNameTip}}</div>
                    </div>
                    <div class="comfire-phone">
                        <span>手机号码</span>
                        <input type="text" placeholder="请输入您的联系方式" v-model="mobile" />
                        <div class="comfire-msg">{{mobileTip}}</div>
                    </div>
                    <div class="comfire-up">
                        <div class="comfire-up-title">营业执照</div>
                        <div class="comfire-up-apply">
                            <el-upload
                                accept="image/*"
                                :http-request="upload"
                                :show-file-list="false"
                                action="">
                                <img
                                class="idimg"
                                :src="idimg ? idimg : require('/static/images/please.png')"
                                alt=""
                                />
                            </el-upload>
                            <span class="apply-img">请上传营业执照</span>
                        </div>
                        <div>
                            <el-image
                                style="width: 230px; height: 170px"
                                :src="url"
                                :preview-src-list="srcList">
                            </el-image>
                        </div>
                    </div>
                    <div class="content5">
                        <span @click="quit">取消</span>
                        <span @click="send">提交</span>
                    </div>
                </div>
                <div class="content-list" v-show="change == 'test'">
                    <div class="content-list-box">
                        <div class="content-list-middle" v-for="(v,k) in list" :key="k">
                            <div class="middle1">
                                <img :src="v.businessLicense" alt="">
                            </div>
                            <div class="middle2">
                                <div><span>公司名称：</span><span>{{v.businessName}}</span></div>
                                <div><span>手机号码：</span><span>{{v.mobile}}</span></div>
                                <div><span>法人名称：</span><span>{{v.legalPerson}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </div>  
  </div>
</template>

<script>
import client from "@/oss.js";
import moment from "moment";
import Else from '../ui/else.vue';
export default {
  name: 'comfire',
  data(){
      return{
         change:'apply',
         url: "https://guolao-pro.oss-cn-hangzhou.aliyuncs.com/pc/202207121329img.jpg",
        srcList: [
            "https://guolao-pro.oss-cn-hangzhou.aliyuncs.com/pc/202207121329img.jpg",
        ],
        companyName:'',
        companyNameTip:'',
        mobile:'',
        mobileTip:'',
        idimg:'',
        list:[]
      }
  },
  watch:{
    mobile(newValue) {
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (newValue == "") {
        return (this.mobileTip = "请输入手机号码");
      }
      if (newValue.length != 11 || !reg.test(newValue)) {
        return (this.mobileTip = "手机号格式不正确");
      }
      return (this.mobileTip = "");
    },
    companyName(newValue) {
      if (newValue == "") {
        return (this.companyNameTip = "请输入合法的公司名称");
      }
      this.companyNameTip = "";
    },
  },
  components: {
      Else
  },
  computed: {
    bj() {
      return this.$route.query.company
    }
  },
  methods:{
     tip(type, msg) {
      return this.$message({
        type: type,
        message: msg,
        showClose: true,
        duration: 1500,
      });
    },
     async upload(e) {
      let fileName = moment().format("YYYYMMDDmmss") + e.file.name;
      try {
        let result = await client.put("/pc/" + fileName, e.file);
        if (result.url != "") {
          this.idimg = result.url;
        }
      } catch (e) {
        console.log(e);
      }
    },
   changetype(val){
    // console.log(this.bj)
    this.change = val
   },
   send(){
     if (this.companyName == "") {
          this.companyNameTip = "请输入企业名称";
          return false;
        } else if (this.mobile == "") {
          this.mobileTip = "请输入手机号码";
          return false;
        } else if (this.mobileTip == "手机号格式不正确") {
          return false;
        }  else if (this.idimg == "") {
          return false;
        } else {
          this.$http
            .post(
              this.GLOBAL.new_url + "/user/service/cooperator/insertBusiness",
              {
              
                userBusinessCode:this.bj,
                businessName: this.companyName,
                mobile: this.mobile,
                businessLicense: this.idimg
              }
            ).then((res) => {
                console.log(res)
              if (res.data.code == "200") {
                this.tip("success", "提交成功");
                // this.companyName="";
                // this.companyNameTip="";
                // this.mobile="";
                // this.mobileTip="";
                // this.idimg="";
              } else {
                // this.tip("error", res.data.msg);
              }
            });
        }
   },
   quit(){
    this.$router.go(-1)
   }   
       
  },
  mounted(){
       this.$http .post(
              this.GLOBAL.new_url + "/user/service/cooperator/getBusinessList",{userBusinessCode:this.bj}
            ).then((res) => {
                // console.log(res)
              if (res.data.code == "200") {
               this.list = res.data.data;
               //子向父发送数据
              this.$nextTick(()=>{
                var height =  document.documentElement.scrollHeight;
                console.log(height)
                  window.parent.postMessage(
                  {
                    data: height,
                  },
                  "*"
                );
              })
              } else {
                this.tip("error", res.data.msg);
              }
            });
  }
}

</script>

<style scoped>
#comfire{
    padding-bottom: 30px;
}
   .comfire-box{
    width: 1015px;
    margin: 0 auto;
    padding-top: 48px;
   }
    .comfire-title{
        height: 28px;
        border-bottom: 1px solid #D4D4D4;
        font-size: 20px;
        margin-bottom: 40px;
    }
    .comfire-middle{
        width: 1015px;
        height: 692px;
        background: #FFFFFF;
        box-shadow: 0px 0px 10px 0px rgba(85,84,91,0.2700);
        border-radius: 4px;
        margin-bottom: 55px;
        padding-top: 33px;
    }
    .comfire-content{
        width: 755px;
        margin: 0 auto;
        /* border:1px solid red; */
    }
    .content1{
        font-size: 20px;
        width: 200px;
        text-align: center;
        margin: 0 auto 5px;
    }
    .content2{
        width: 755px;
        color: #B8B8B8;
        font-size: 14px;
        text-align: center;
        margin-bottom: 15px;
    }
    .content3>div{
        height: 38px;
    }
    .content3>div>div{
        float: left;
        width: 334px;
        height: 38px;
        cursor: pointer;
        color: #878787;
        font-size: 16px;
        text-align: center;
        line-height: 38px;
        border-radius: 25px;
        background: #F7F7F7;
        border-radius: 25px;
        border: 1px solid #C4C4C4;
    }
    .testm{
        margin-left: 80px;
    }
    .apply .applym,.test .testm{
        background: #2553B3;
        color: #FFFFFF;
    }
    .content4{
        margin-top: 70px;
    }
    .content4>div>span{
        font-size: 16px;
        vertical-align: middle;
        margin-right: 23px;
    }
    .content4>div>input{
        vertical-align: middle;
        width: 217px;
        height: 34px;
        padding-left: 14px;
        border-radius: 5px;
        border: 1px solid #CFCFCF;
        outline: none;
    }
    .comfire-msg{
        height: 27px;
        line-height: 27px;
        margin-left: 100px;
        font-size: 14px;
        color: red;
    }
    .comfire-up{
        height: 200px;
    }
    .comfire-up>div{
        float: left;
    }
    .comfire-up-title{
        font-size: 16px;
    }
    .comfire-up-apply{
        text-align: center;
        margin: 0 130px 0 27px;
    }
    .idimg{
        width: 231px;
        height: 159px;
    }
    .apply-img{
       color: #979797;
       font-size: 14px;
       margin-top: 10px;
    }
    .content5{
        width: 350px;
        margin: 70px auto 0;
    }
    .content5>span{
        display: inline-block;
        width: 142px;
        height: 27px;
        background: #E9F0FF;
        border-radius: 6px;
        line-height: 27px;
        cursor: pointer;
        text-align: center;
        border: 1px solid #2554B3;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2554B3;
    }
    .content5>span:nth-of-type(2){
        color: #FFFFFF;
        background: #2554B3;
        margin: 0 0 0 33px;
    }
    .content-list{
        margin-top: 24px;
    }
    .content-list-box{
        padding: 25px;
        height: 500px;
        width: 895px;
        border:1px solid #E3E1E1;
        margin-left: -100px;
        overflow-y: auto;
    }
    .content-list-box::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        }

.content-list-box::-webkit-scrollbar-thumb {
  border-radius: 5px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

.content-list-box::-webkit-scrollbar-track {
  opacity: 0;
}
.content-list-middle{
    width: 827px;
   height: 179px;
   border: 1px solid #E3E3E3;
   margin: 0 auto 5px;
}
.content-list-middle>div{
    float: left;
}
.middle1{
    margin: 23px 60px 0 27px;
}
.middle1>img{
    width: 173px;
    height: 126px;
}
.middle2{
    margin-top: 45px;
}
.middle2>div{
    margin-bottom: 8px;
}
.middle2>div>span:nth-of-type(1){
    font-size: 16px;
}
.middle2>div>span:nth-of-type(2){
    font-size: 14px;
    font-weight: 400;
}
</style>
